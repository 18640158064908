import React, { Component } from 'react';
import select from 'unist-util-select';
import Link from 'gatsby-link';
import styled from 'styled-components';

const Section = styled('section')`
  ul {
    margin-left: 1.6em;
    list-style-type: disc;
  }
  .feature h4 + p {
    max-width: none;
  }
  .feature__body ul {
    margin-left: 1em;
  }
`;

class ServicesList extends Component {
  render() {
    const {
      data: { section },
    } = this.props;
    const titles = select(section.htmlAst, 'root > element[tagName=h3] text');
    const lists = select(section.htmlAst, 'root > element[tagName=ul]');
    const images = select(section.htmlAst, 'element[tagName=img]');
    // const links = select(
    //   section.htmlAst,
    //   'root > element[tagName=p] element[tagName=a]'
    // );
    //
    return (
      <Section className="unpad">
        <div className="container">
          <div className="row">
            {titles.map((title, i) => {
              let items;
              if (lists[i]) {
                items = select(lists[i], 'element[tagName=li] text');
              } else {
                items = select(
                  section.htmlAst,
                  'element[tagName=p]:last-child text'
                );
              }
              return (
                <div className="col-md-4" key={title.value}>
                  <div className="feature feature-2 ">
                    <img
                      style={{ width: '20%' }}
                      alt={images[i].properties.alt}
                      src={images[i].properties.src}
                    />
                    <div className="feature__body">
                      <h4>{title.value}</h4>
                      {items.length > 1 ? (
                        <ul>
                          {items.map((item, i) => {
                            return (
                              <li key={title.value + item.value + i}>
                                {item.value}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <React.Fragment>
                          <p>{items[0].value}</p>
                          <p>
                            <Link to="/contact" className="btn type--uppercase">
                              <span className="btn__text">Contact Us</span>
                            </Link>
                          </p>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    );
  }
}

export default ServicesList;

import React, { Component } from 'react';
import Content from '../../components/Content';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Section = styled('section')`
  ${breakpoint('lg')`
    &:first-child {
      h1 {
        display: inline-block;
      \}
    \}
  `};
`;

class Page extends Component {
  render() {
    const {
      data: { page },
    } = this.props;
    return (
      <Section className="space--xs">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <Content content={page.html} />
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

export default Page;

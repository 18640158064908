import React, { Component } from 'react';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import { convertSlugToPath } from '../../utils/helpers';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

const Article = styled('article')`
  height: 256px;
  margin-bottom: 1.6em;
  & h4 {
    margin: 0;
  }
  &.imagebg .article__title {
    margin-bottom: 1em;
  }
`;

class Process extends Component {
  render() {
    return (
      <StaticQuery query={query}>
        {({ posts }) => (
          <section className="space--sm">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    {posts &&
                      posts.edges.map(({ node }) => {
                        return (
                          <div className="col-md-4 col-12" key={node.id}>
                            <Link
                              className="block"
                              to={convertSlugToPath(node.fields.slug)}
                            >
                              <Article
                                className="imagebg border--round"
                                data-scrim-bottom={9}
                              >
                                <div className="background-image-holder">
                                  {node.frontmatter.image && (
                                    <Img
                                      alt="background"
                                      fluid={
                                        node.frontmatter.image.childImageSharp
                                          .fluid
                                      }
                                    />
                                  )}
                                </div>
                                <div className="article__title">
                                  {/* <span>{node.frontmatter.date}</span> */}
                                  <h4>{node.frontmatter.title}</h4>
                                  <p>{node.frontmatter.subtitle}</p>
                                </div>
                              </Article>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </StaticQuery>
    );
  }
}

export default Process;

const query = graphql`
  query {
    posts: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "blog" }, status: { eq: "active" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid(
                  maxWidth: 350
                  maxHeight: 256
                  cropFocus: SOUTH
                  quality: 75
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

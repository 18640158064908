import React, { Component, Fragment } from 'react';
import Img from 'gatsby-image';
import LinkedIn from 'mdi-material-ui/Linkedin';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { StaticQuery, graphql } from 'gatsby';

import { fixWidows } from '../../utils/helpers';
import blankAvatar from '../../images/blank-avatar.png';

const Section = styled('section')`
  & h5 {
    margin-bottom: 1em;
  }
  & i::before {
    font-size: 24;
    line-height: 33px;
    margin-right: 0.5em;
    vertical-align: -20%;
  }
  & .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const ProfilePicture = styled(Img)`
  max-width: 350px;
  margin-bottom: 1.5em;
`;
const BlankProfilePicture = styled('img')`
  max-width: 100%;
  margin-bottom: 1.5em;
`;
const Profiles = styled('div')`
  /* ${breakpoint('lg')`
    div:nth-child(4n) {
      margin-left: 15%;
    \}
  `}; */
`;

class People extends Component {
  render() {
    const {
      data: { section },
    } = this.props;
    return (
      <StaticQuery query={query}>
        {({ people, bgBlueprint }) => (
          <Fragment>
            <Section className="text-center imagebg">
              <div className="background-image-holder">
                <Img
                  alt="background"
                  fluid={bgBlueprint.childImageSharp.fluid}
                />
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-8">
                    <h3>{section.frontmatter.title}</h3>
                    <p className="lead">
                      {fixWidows(section.frontmatter.lead)}
                    </p>
                  </div>
                </div>
              </div>
            </Section>
            <Section>
              <div className="container">
                <Profiles className="row">
                  {people.edges.map(({ node }) => {
                    let columns = 4;
                    if (people.edges.length < 5) {
                      columns = 6;
                    }
                    return (
                      <div className={`col-md-${columns}`} key={node.id}>
                        <div className="feature">
                          {node.frontmatter.image ? (
                            <ProfilePicture
                              alt="feature"
                              fluid={
                                node.frontmatter.image.childImageSharp.fluid
                              }
                              className="border--round"
                            />
                          ) : (
                            <BlankProfilePicture
                              alt="feature"
                              src={blankAvatar}
                              className="border--round"
                            />
                          )}
                          <h4>{node.frontmatter.title}</h4>
                          <h5>{node.frontmatter.jobTitle}</h5>
                          <p>
                            {/* <a href="tel:3308999633">
                              <i className="icon icon--sm icon-Phone-2" />
                              {node.frontmatter.phone}
                            </a> */}
                            {/* <br /> */}
                            <a href={`mailto:${node.frontmatter.email}`}>
                              <i className="icon icon--sm icon-Email" />
                              {node.frontmatter.email}
                            </a>
                          </p>
                          <p>{fixWidows(node.frontmatter.description)}</p>
                          {node.frontmatter.linkedIn && (
                            <a
                              href={node.frontmatter.linkedIn}
                              target="_blank"
                              rel="noopener noreferrer">
                              <LinkedIn />
                            </a>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </Profiles>
              </div>
            </Section>
          </Fragment>
        )}
      </StaticQuery>
    );
  }
}

export default People;

const query = graphql`
  query {
    bgBlueprint: file(relativePath: { eq: "blueprints.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 1280
          maxHeight: 360
          duotone: { highlight: "#304c6d", shadow: "#183152" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    people: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "people" }, status: { eq: "active" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            phone
            email
            linkedIn
            jobTitle
            image {
              childImageSharp {
                fixed(width: 360, height: 360, quality: 80) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
                fluid(maxWidth: 360, maxHeight: 360, quality: 80) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          html
        }
      }
    }
  }
`;

import React, { Component } from 'react';
import select from 'unist-util-select';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Section = styled('section')`
  & h4 + p {
    max-width: none;
  }
`;

class Process extends Component {
  render() {
    const {
      data: { section },
    } = this.props;
    const titles = select(section.htmlAst, 'root > element[tagName=h3] text');
    const texts = select(section.htmlAst, 'root > element[tagName=p] > text');
    return (
      <Section className="space--sm">
        <div className="container">
          <div className="row">
            {titles.map((title, i) => {
              return (
                <div className="col-md-4" key={title.value}>
                  <div className="feature">
                    <Img
                      alt="feature"
                      fluid={
                        section.frontmatter.images[i].image.childImageSharp
                          .fluid
                      }
                      className="border--round mb--1"
                    />
                    <h4>{title.value}</h4>
                    <p>{texts[i].value}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    );
  }
}

export default Process;

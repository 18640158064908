import React, { Component } from 'react';

class Blank extends Component {
  render() {
    const { title = 'Blank Section Template' } = this.props;
    return (
      <section>
        <div className="container pos-vertical-center">
          <div className="row">
            <div className="col-md-12">
              <h1>{title}</h1>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Blank;

import React, { Component } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Carousel from 'nuka-carousel';

const Section = styled('section')`
  & .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    height: 100%;
  }
  max-height: 356px;
`;

class Testimonials extends Component {
  render() {
    return (
      <StaticQuery query={query}>
        {({ testimonials, bgImage }) => {
          if (!testimonials || !bgImage) return null;
          return (
            <Section className="text-center imagebg">
              <div className="background-image-holder">
                <Img alt="background" fluid={bgImage.childImageSharp.fluid} />
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-10">
                    <div className="slider">
                      <Carousel
                        autoplay={false}
                        wrapAround
                        heightMode="max"
                        withoutControls
                      >
                        {testimonials.edges.map(({ node }) => (
                          <div key={node.id} className="testimonial">
                            <blockquote>{node.frontmatter.quote}</blockquote>
                            <h5>{node.frontmatter.title}</h5>
                            <span className="type--fade">
                              {node.frontmatter.location}
                            </span>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          );
        }}
      </StaticQuery>
    );
  }
}

export default Testimonials;

const query = graphql`
  query {
    testimonials: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "testimonial" }, status: { eq: "active" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            location
            quote
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "blueprints.jpg" }) {
      childImageSharp {
        fluid(
          quality: 80
          maxWidth: 1280
          maxHeight: 360
          duotone: { highlight: "#304c6d", shadow: "#183152" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

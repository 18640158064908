import React, { Component } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Link, StaticQuery, graphql } from 'gatsby';
import Carousel from 'nuka-carousel';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { IconButton } from '@rmwc/icon-button';
import SecondaryHeader from '../../components/Secondary';

const Section = styled('section')`
  .imagebg {
    height: 50vh;
    width: 100%;
    min-height: 450px;
    ${breakpoint('xs', 'lg')`
      min-height: calc(375px - 56px);
    `};
  }
  .background-image-holder img {
    width: 100%;
    height: 100%;
  }
  .gatsby-image-wrapper,
  .gatsby-image-outer-wrapper {
    height: 100%;
  }
`;
const SlideContent = styled('div')`
  height: 100%;
  ${breakpoint('lg')`
    padding-top: 112px;
  `};
  .row {
    height: 100%;
  }
  .col-md-8 {
    flex: auto;
    align-self: center;
  }
`;

class Cover extends Component {
  render() {
    return (
      <StaticQuery query={queryCovers}>
        {({ covers }) => (
          <Section
            className="cover imagebg text-center slider slider--ken-burns"
            data-arrows={'true'}
            data-paging={'true'}
          >
            <Carousel
              autoplay
              wrapAround
              renderBottomCenterControls={null}
              renderCenterRightControls={({ nextSlide }) => (
                <IconButton
                  style={{ color: 'white' }}
                  icon={<MdChevronRight />}
                  onClick={nextSlide}
                />
              )}
              renderCenterLeftControls={({ previousSlide }) => (
                <IconButton
                  style={{ color: 'white' }}
                  icon={<MdChevronLeft />}
                  onClick={previousSlide}
                />
              )}
            >
              {[...covers.edges, ...covers.edges].map(({ node }) => {
                return (
                  <div className="imagebg" key={node.id}>
                    <div className="background-image-holder background--top">
                      <Img
                        fluid={node.frontmatter.image.childImageSharp.fluid}
                        alt="background"
                      />
                    </div>
                    <SlideContent className="container">
                      <div className="row">
                        <div className="col-md-8">
                          <h1
                            dangerouslySetInnerHTML={{
                              __html: node.frontmatter.title,
                            }}
                          />
                          <div
                            className="lead"
                            dangerouslySetInnerHTML={{ __html: node.html }}
                          />
                          <Link
                            className="btn btn--primary type--uppercase"
                            to={node.frontmatter.callToActionLink}
                          >
                            <span className="btn__text">
                              {node.frontmatter.callToActionText}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </SlideContent>
                  </div>
                );
              })}
            </Carousel>
            <SecondaryHeader />
          </Section>
        )}
      </StaticQuery>
    );
  }
}

export default Cover;

const queryCovers = graphql`
  query {
    covers: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "cover" }, status: { eq: "active" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(
                  maxWidth: 1280
                  maxHeight: 720
                  quality: 80
                  cropFocus: CENTER
                  duotone: {
                    highlight: "#222d32"
                    shadow: "#000000"
                    opacity: 75
                  }
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            callToActionLink
            callToActionText
          }
          html
        }
      }
    }
  }
`;

import React, { Component } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Link, StaticQuery, graphql } from 'gatsby';
import { Icon } from '@rmwc/icon';
import { MdPhone, MdEmail, MdLocationOn } from 'react-icons/md';

const Section = styled('section')`
  &&& {
    font-size: 14px;
  }
  background: #00579a !important;
  color: white;
  &.bar.bar--sm {
    padding: 4px 0;
  }
  .bar__module {
    a {
      display: inline-block;
    }
    .icon {
      font-size: 24px;
      margin-right: 0.25em;
      &::before {
        vertical-align: middle;
      }
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
    margin: 0;
    line-height: 24px;
    ${breakpoint('lg')`
      line-height: 36px;
    `};
  },
`;

class SecondaryHeader extends Component {
  render() {
    return (
      <StaticQuery query={query}>
        {({ site }) => (
          <Section className="bar bar-3 bar--sm bg--secondary">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 hidden-xs hidden-sm">
                  <div className="bar__module text-left">
                    <Icon
                      style={{ verticalAlign: 'middle' }}
                      className="icon icon--sm type--fade"
                      icon={<MdLocationOn />}
                    />
                    <Link to="/contact" className="type--fade">
                      {site.siteMetadata.description}
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 text-right text-left-xs text-left-sm">
                  <div className="bar__module">
                    <ul className="menu-horizontal">
                      <li>
                        <a
                          href={`tel:${site.siteMetadata.phone.replace(
                            '-',
                            ''
                          )}`}>
                          <Icon
                            style={{ verticalAlign: 'middle' }}
                            className="icon icon--sm"
                            icon={<MdPhone />}
                          />
                          <span>{site.siteMetadata.phone}</span>
                        </a>
                      </li>
                      <li>
                        <a href={`mailto:${site.siteMetadata.email}`}>
                          <Icon
                            style={{ verticalAlign: 'middle' }}
                            className="icon icon--sm"
                            icon={<MdEmail />}
                          />
                          <span>{site.siteMetadata.email}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        )}
      </StaticQuery>
    );
  }
}

export default SecondaryHeader;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        copyright
        description
        phone
        email
        linkedInUrl
      }
    }
  }
`;

export const fixWidows = text => {
  return text.replace(/\s+([\S]*)(\s*)$/gm, '\u00a0$1$2');
};

export const convertSlugToPath = slug => {
  const nodePath = slug
    .split('-')
    .slice(0, 3)
    .join('/');
  const nodeSlug = slug
    .split('-')
    .slice(3)
    .join('-');
  return `${nodePath}/${nodeSlug}`;
};

import blank from './blank';
import blogRecent from './blog-recent';
import callToAction from './call-to-action';
import cover from './cover';
import features from './features';
import page from './page';
import people from './people';
import servicesList from './services-list';
import testimonials from './testimonials';

export default {
  'blog-recent': blogRecent,
  'call-to-action': callToAction,
  'services-list': servicesList,
  blank,
  cover,
  features,
  page,
  people,
  testimonials,
};
